
import React from "react";
import "./Gallery.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import img1 from "../images/gallery-1.jpg";
import img2 from "../images/gallery-2.jpg";
import img3 from "../images/gallery-3.jpg";
import img4 from "../images/gallery-4.jpg";
import img5 from "../images/gallery-5.jpg";
import img6 from "../images/gallery-6.jpg";
import img7 from "../images/gallery-7.jpg";
import img8 from "../images/gallery-8.jpg";
import img9 from "../images/gallery-9.jpg";
import img10 from "../images/gallery-10.jpg";
import img11 from "../images/gallery-11.jpg";
import img12 from "../images/gallery-12.jpg";
import img13 from "../images/gallery-13.jpg";
import img14 from "../images/gallery-14.jpg";
import img15 from "../images/gallery-15.jpg";
import img16 from "../images/gallery-16.jpg";
import img17 from "../images/gallery-17.jpg";
import img18 from "../images/gallery-18.jpg";
import img19 from "../images/gallery-19.jpg";
import img20 from "../images/gallery-20.jpg";
import img21 from "../images/gallery-21.jpg";
import img22 from "../images/gallery-22.jpg";
import img23 from "../images/gallery-23.jpg";


// Steps
// 1. Download all the new images and place them under the images/ folder here
// 2. Go to https://www.birme.net/
// 3. Drop all the images (including the existing ones) into their bulk resizer/cropper
// 4. Ensure that the existing images don't get changed, but the new images get cropped
// 5. Download all as zip
// 6. Replace all the new images in the zip to the images/ folder here
// 7. Update the import statements and components here to add the new images.


const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
const Gallery = () => {
    return (
        <div className="Gallery">
            <Carousel responsive={responsive} infinite={true}>
                <div><img className="imgSlider" src={img1} alt="demo" /></div>
                <div><img className="imgSlider" src={img2} alt="demo" /></div>
                <div><img className="imgSlider" src={img3} alt="demo" /></div>
                <div><img className="imgSlider" src={img4} alt="demo" /></div>
                <div><img className="imgSlider" src={img5} alt="demo" /></div>
                <div><img className="imgSlider" src={img6} alt="demo" /></div>
                <div><img className="imgSlider" src={img7} alt="demo" /></div>
                <div><img className="imgSlider" src={img8} alt="demo" /></div>
                <div><img className="imgSlider" src={img9} alt="demo" /></div>
                <div><img className="imgSlider" src={img10} alt="demo" /></div>
                <div><img className="imgSlider" src={img11} alt="demo" /></div>
                <div><img className="imgSlider" src={img12} alt="demo" /></div>
                <div><img className="imgSlider" src={img13} alt="demo" /></div>
                <div><img className="imgSlider" src={img14} alt="demo" /></div>
                <div><img className="imgSlider" src={img15} alt="demo" /></div>
                <div><img className="imgSlider" src={img16} alt="demo" /></div>
                <div><img className="imgSlider" src={img17} alt="demo" /></div>
                <div><img className="imgSlider" src={img18} alt="demo" /></div>
                <div><img className="imgSlider" src={img19} alt="demo" /></div>
                <div><img className="imgSlider" src={img20} alt="demo" /></div>
                <div><img className="imgSlider" src={img21} alt="demo" /></div>
                <div><img className="imgSlider" src={img22} alt="demo" /></div>
                <div><img className="imgSlider" src={img23} alt="demo" /></div>


            </Carousel>
        </div>
    )
}

export default Gallery;