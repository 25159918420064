import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import img from "../images/Syd.jpg";
import img2 from "../images/Syd-lesson.jpg";

import "./Info.css";

const Info = () => {
    return (
        <div className="Info">
          <div className="tabContainer">
            <Tabs
                defaultActiveKey="home"
                className="tabPane"
                fill
            >
                <Tab eventKey="home" title="About me">
                <Figure>
                  <Figure.Image className="imahe"
                      src={img}
                    />
                    <Figure.Caption align="justify" className="yikes">
                     <div>{`Hello Everyone!`}</div>
                      <br/>
                     <div>{`I'm Sydney Penwell, your makeup artist based in Burlington, Ontario. While I specialize in making brides and special event attendees look stunning, I'm also passionate about all things makeup—from editorial looks to special effects.`}</div>
                     <br/>
                     <div>{`I graduated with honors from Sheridan College's Makeup for Media and Creative Arts program, where I learned everything from the best tips to the latest techniques in makeup application.`}</div>
                     <br/>
                     <div>{`My journey began at Sephora, where I gained valuable experience working with all kinds of skin types, tones, and textures. I even had the pleasure of mentoring new artists as a Services Lead, which sparked my love for teaching.`}</div>
                     <br/>
                     <div>{`Now, I've embarked on my own business journey, focusing on making weddings magical.`}</div>
                     <br/>
                     <div>{`What I love most about my job? It's that incredible moment when my clients see themselves in a whole new light. There's nothing like seeing someone feel confident and beautiful. Plus, who doesn't enjoy a good love story?`}</div>
                     <br/>
                     <div>{`Let's create some magic together!`}</div>
                    </Figure.Caption>
                </Figure>

                </Tab>
                <Tab eventKey="profile" title="Services & Pricing" className="tabContentInner">
                <Row className='customRow'>
                <Col md={4}>
                  <Card>
                    <Card.Body>
                    <Card.Title>Bride</Card.Title>
                      <div><span className='leftText'>{"Brides Day Makeup"}</span><span className='rightText'>{" - 1 - 1.5 hr service, $170"}</span></div>
                      <br/>
                      <div><span className='leftText'>{"Bridal Trial"}</span><span className='rightText'>{" - 1.5 - 2 hr service, $150"}</span></div>
                      <br/>
                      <div>{`Trials are not necessary but are recommended and are scheduled 4-6 weeks before wedding`}</div>
                    </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                  <Card className="middleCard">
                    <Card.Body>
                    <Card.Title>Attendants</Card.Title>
                      <div><span className='leftText'>{"Bridesmaids Makeup"}</span><span className='rightText'>{" -  1 hr service, $150"}</span></div>
                      <br/>
                      <div><span className='leftText'>{"Mothers Makeup"}</span><span className='rightText'>{" - 1 hr service, $150"}</span></div>
                      <br/>
                      <div><span className='leftText'>{"Juniors Makeup"}</span><span className='rightText'>{" - 45 min service, $70 (Includes natural soft makeup for those 14 and under)"}</span></div>
                      <br/>
                    </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                  <Card>
                    <Card.Body>
                    <Card.Title>Special Events</Card.Title>
                      <div><span className='leftText'>{"Special Events Makeup"}</span><span className='rightText'>{" - 45 min service, $135"}</span></div>
                    <Card.Title style={{ marginTop: "2vh"}}>Add ons & services fee</Card.Title>
                      <div><span className='leftText'>{"On Location Touch Ups"}</span><span className='rightText'>{" - $100 per hour"}</span></div>
                      <br/>
                      <div><span className='leftText'>{"The Groom"}</span><span className='rightText'>{" - $60 (skin correction and shine control)"}</span></div>
                      <br/>
                      <div><span className='leftText'>{"Tattoo Cover Up"}</span><span className='rightText'>{" - price determined on size of tattoo"}</span></div>
                      <br/>
                    </Card.Body>
                    </Card>
                  </Col>
                </Row>
                  <Row>
                  <Col sm={12}>
                      <div className='note'>
                        <div className="noteHeader"><span className="noteHeaderTXT">{`Important notes from Sydney`}</span></div>
                        <br/>
                        <div>{`All prices are subject to change`}</div>
                        <div>{`Travel/Parking fees do apply and to be determined based on location of event`}</div>
                        <div>{`I am not available for airbrush makeup or complex hair services (up-dos)`}</div>
                        <div>{`To secure your date with me, I require a signed agreement and 30% non-refundable retainer.`}</div>
                      </div>
                  </Col>
                  </Row>
                </Tab>
                <Tab eventKey="beauty" title="Beauty lesson">
                <Figure>
                  <Figure.Image className="imahe"
                      src={img2}
                    />
                    <Figure.Caption align="justify" className="yikes">
                    <div className="beautyContent">
                      <div>{`Hey everyone! Welcome to my beauty lesson section. I am so excited to share with you my ultimate skincare and makeup routine. Whether you're just starting out or looking to perfect your routine, I've got you covered. Let's dive in and get that flawless, glowing look together!`}</div>
                      <br/>
                      <div className='leftText'>{"This includes:"}</div>
                      <br/>
                      <ul>
                        <li>{"Skincare consultation to review and optimize your routine"}</li>
                        <li>{"Makeup consultation to assess and enhance your products"}</li>
                        <li>{"I will demonstrate makeup techniques on half of your face while you replicate the other half"}</li>
                        <li>{"Custom makeup routine using a blend of your products and mine"}</li>
                      </ul>
                      <br/>
                      <div className='leftText'>{"90 min lesson, $150"}</div>
                      <br/>
                      <div><span className='leftText'>{"Add on - "}</span><span className='rightText'>{"take home a brush kit that I'll be using on you to achieve the look, $50"}</span></div>
                      </div>
                    </Figure.Caption>
                </Figure>
                </Tab>

                <Tab eventKey="faq" title="FAQ" className="FaqMe">
                <Accordion className="agree">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{"What is a trial and is it required?"}</Accordion.Header>
                      <Accordion.Body>
                      {`Bridal trials give us a chance to meet, discuss your style, and try out your makeup vision. Although trials aren't mandatory, they are recommended!`}
                      </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{"How much in advance should I schedule my trial?"}</Accordion.Header>
                      <Accordion.Body>
                      {`I recommend 4-6 weeks out from your event date.`}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>{"Do you require a retainer to book?"}</Accordion.Header>
                      <Accordion.Body>
                      {`To secure your date with me, I require a signed agreement and 30% non-refundable retainer.`}
                      </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>{"How much time should I allow for makeup on my event date?"}</Accordion.Header>
                      <Accordion.Body>
                        {"Make sure to allow at least an hour for wedding-related and special event makeup."}
                      </Accordion.Body>
                  </Accordion.Item>
                  </Accordion>
                </Tab>

            </Tabs>
            </div>
        </div>
    )
}

export default Info;
